// constants.js

const CONSTANTS = {
    APP_NAME: "Soul Strength",
    APP_NAME_Long: "Soul Strength Counseling and Wellness",
    ENV: process.env.REACT_APP_ENVIRONMENT,
    IS_UNDER_MAINTENANCE: process.env.REACT_APP_IS_UNDER_MAINTENANCE
};

export default CONSTANTS;
