import React, { useEffect, useState } from 'react';
import styles from './ParallaxContainer.module.scss';

const ParallaxContainer = ({ children }) => {
    const [scrollY, setScrollY] = useState(0);

    // Track the scroll position and update state
    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    // Dynamic style calculation for parallax effect
    const getDynamicStyle = (index) => {
        // The first child scrolls normally, so no transform
        if (index === 0) return {};

        // For other children, apply progressively slower rates
        const rate = scrollY / (index * 3); // Adjust the denominator for slower movement
        return {
            transform: `translateY(${rate}px)`,
        };
    };

    return (
        <div className={styles.parallaxWrapper}>
            {React.Children.map(children, (child, index) => (
                <div style={getDynamicStyle(index)}>
                    {child}
                </div>
            ))}
        </div>
    );
};

export default ParallaxContainer;
