import React from 'react';
import PublicLayout from './public/PublicLayout';
import HomePage from '../pages/public/Home/HomePage';
import MaintenancePage from "./public/Maintenance/MaintenancePage";
import CONSTANTS from "../constants";

// Maintenance flag
const isUnderMaintenance = CONSTANTS.IS_UNDER_MAINTENANCE === 'true';
const routes = isUnderMaintenance
    ? [
        {
            path: '*',
            element: <MaintenancePage />,
        },
    ]
    : [
        {
            path: '/',
            element: <PublicLayout />,
            children: [
                { index: true, element: <HomePage /> },
            ],
        },
    ];

export default routes;
