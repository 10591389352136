import { useState, useEffect, useRef } from 'react';

export const useParallax = () => {
    const [scrollY, setScrollY] = useState(0);
    const ref = useRef(null); // Ref for the element being tracked

    useEffect(() => {
        const handleScroll = () => {
            setScrollY(window.scrollY); // Update scroll position on every scroll
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll); // Cleanup listener
        };
    }, []);

    const getParallaxStyle = (index) => {
        if (!ref.current) return {};

        // Get the element's top position relative to the document
        const elementTop = ref.current.getBoundingClientRect().top + window.scrollY;

        // Calculate the parallax effect based on its position relative to scroll
        const viewportHeight = window.innerHeight;
        const distanceFromViewportTop = elementTop - scrollY;
        const rate = distanceFromViewportTop / (index * 3);

        // Always apply parallax effect to the element based on the scroll position
        return {
            transform: `translateY(${rate}px)`,
            transition: 'transform 0.2s ease-out', // Smooth transition
        };
    };

    return { ref, getParallaxStyle };
};
