// src/ThemeContext.js
import React from 'react';
import colors from './_variables.scss'; // Import the SCSS module

// Create a Context with the fonts values from the SCSS module
const ThemeContext = React.createContext(colors);

export const ThemeProvider = ({ children }) => {
    return (
        <ThemeContext.Provider value={colors}>
            {children}
        </ThemeContext.Provider>
    );
};

// Custom hook to use the fonts
export const useTheme = () => React.useContext(ThemeContext);
