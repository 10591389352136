import React from 'react';
import img from './images/image.jpg';
import { useParallax } from '../services/parallax'; // Import the hook

const Section4 = () => {
    const { ref, getParallaxStyle } = useParallax();

    return (
        <div className="section4">
            <div className="box">
                <h4>This is a box</h4>
                <p>Welcome to a space where your well-being is the priority. Whether you're seeking support for personal growth, emotional challenges, or life transitions</p>
            </div>
            <div className={"parallaxWrapper"}>
                <img
                    ref={ref} // Attach ref for tracking
                    style={getParallaxStyle(4)} // Apply parallax style
                    src={img}
                    alt="Image"
                />
            </div>

        </div>
    );
};

export default Section4;
