import React from 'react';
import styles from './HeroSection.module.scss';

const HeroSection = ({ imageUrl, altText }) => {
    return (
        <div className={styles.heroSection}>
            <img src={imageUrl} alt={altText} className={styles.heroImage} />
        </div>
    );
};

export default HeroSection;
