import React from 'react';
import img from './images/image.jpg'

const Section3 = () => {
    return (
        <div className="section section3">
            <div className="margin">
                <div className="rows">
                    <div className="square">
                        <img src={img} alt={"This is an image"} />
                    </div>
                    <div className="square squarePad">
                        <h5>How Professional Support Can Help You Navigate Life’s Challenges</h5>
                        <div >Welcome to a space where your well-being is the priority. Whether you're seeking support for personal growth, emotional challenges, or life transitions, I am here to guide you every step of the way. Together, we will create a plan that addresses your unique needs and helps you move forward with confidence and clarity</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Section3;