import React from 'react';

const Section1 = () => {
    return (
        <div className="section section1">
            <div className="margin">
                <h5 className={"center cursive"}>Helping You Thrive Through Life’s Challenges</h5>
                <div className="rows">
                    <p>
                        At my counseling practice, I am committed to providing a safe, non-judgmental space where you
                        can explore your thoughts and emotions. I believe that everyone has the potential to overcome
                        challenges and lead a fulfilling life, and my goal is to support you on that journey. Through
                        personalized, evidence-based approaches, we will work together to identify your strengths,
                        navigate obstacles, and create meaningful change.
                    </p>
                    <p>
                        Life can present many challenges, and seeking support is a courageous first step toward healing.
                        My approach to counseling is centered around understanding your unique experiences and providing
                        compassionate, personalized care. Together, we will work to uncover the root of your concerns
                        and develop practical strategies for managing stress, improving relationships, and enhancing
                        your overall well-being.
                    </p>
                </div>
            </div>

        </div>
    )
}

export default Section1;