import React from 'react';
import img1 from './images/portrait1.jpg';
import img2 from './images/portrait2.jpg';
import img3 from './images/portrait3.jpg';
import {useParallax} from "../services/parallax";

const Section2 = () => {

    const { ref, getParallaxStyle } = useParallax();

    return (
        <div className="section section2">
            <div className="margin">
                <h5 className={"center cursive"}>Helping You Thrive Through Life’s Challenges</h5>
                <div className={"rowImages"}>
                    <img ref={ref} style={getParallaxStyle(2)} src={img1} alt="image 1"/>
                    <img ref={ref} style={getParallaxStyle(3)} src={img2} alt="image 2"/>
                    <img ref={ref} style={getParallaxStyle(5)} src={img3} alt="image 3"/>
                </div>

            </div>

        </div>
    )
}

export default Section2;