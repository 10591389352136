import React from 'react';
import styles from './MaintenancePage.module.scss';

const MaintenancePage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.animation}>
                <div className={styles.gear}></div>
                <div className={styles.gear}></div>
                <div className={styles.gear}></div>
            </div>
            <h1 className={styles.message}>Our website is getting a little TLC! 🛠️</h1>
            <p className={styles.subMessage}>We'll be back up and running shortly. Thank you for your patience!</p>
        </div>
    );
};

export default MaintenancePage;
