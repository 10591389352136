import React from 'react';
import {Outlet} from 'react-router-dom';
import Navbar from "../global/components/Navbar/Navbar";

const PublicLayout = () => (
    <>
        <Navbar />
        <div><Outlet/></div>
    </>
);

export default PublicLayout;
