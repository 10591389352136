import HeroSection from "../../global/components/HeroSection/HeroSection";
import ParallaxContainer from "../../global/components/ParallaxContainer/ParallaxContainer";
import styles from "./HomePage.module.scss";
import FloatBox from "./components/FloatBox/FloatBox";
import Section1 from "../components/Section1/Section1";
import Section2 from "../components/Section2/Section2";
import Section3 from "../components/Section3/Section3";
import Section4 from "../components/Section4/Section4";

const img = 'assets/home/home_hero.jpg'

const HomePage = () => {
    return (
        <div>
            <ParallaxContainer>
                <FloatBox/>
                <HeroSection altText={"This is fun"} imageUrl={img}/>
            </ParallaxContainer>
            <Section1/>
            <Section2/>
            <Section3/>
            <Section4/>
            <Section2/>
            <Section3/>
        </div>
    );
}

export default HomePage;
