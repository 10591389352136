import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import styles from './Navbar.module.scss';
import { useTheme } from '../../../../assets/theme/ThemeContext';
import CONSTANTS from "../../../../constants";
import colors from "../../../../assets/theme/_variables.scss";  // Assuming colors.primary is defined here

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [navbarStyle, setNavbarStyle] = useState({
        backgroundColor: colors.transparent,
        boxShadow: 'none',
    });
    const themeColors = useTheme();

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            const screenHeight = window.innerHeight;

            // Change navbar background and add box-shadow when scroll reaches 100% of screen height
            if (scrollPosition >= screenHeight) {
                setNavbarStyle({
                    backgroundColor: colors.primary, // Set to primary color
                    boxShadow: '0 4px 15px rgba(0, 0, 0, 0.3)', // Add box-shadow
                });
            } else {
                setNavbarStyle({
                    backgroundColor: colors.transparent, // Reset to transparent
                    boxShadow: 'none', // Remove box-shadow
                });
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <nav style={navbarStyle} className={styles.navbar}>
            <div className={styles.navbarContainer}>
                <div className={styles.navbarBrand}>
                    <Link to="/" className={styles.navbarTitle}>
                        {CONSTANTS.APP_NAME}
                        {CONSTANTS.ENV !== "prod" && <sub><i>{` ${CONSTANTS.ENV.toUpperCase()}`}</i></sub>}
                    </Link>
                </div>

                {/* Links for larger screens */}
                <div className={styles.navbarLinks}>
                    <Link to="/" className={styles.navbarItem}>Home</Link>
                    <Link to="/" className={styles.navbarItem}>About</Link>
                    <Link to="/" className={styles.navbarItem}>Contact</Link>
                </div>

                {/* Hamburger menu for smaller screens */}
                <div className={styles.navbarToggle} onClick={toggleMenu}>
                    <span className={styles.bar}></span>
                    <span className={styles.bar}></span>
                    <span className={styles.bar}></span>
                </div>
            </div>

            {/* Sliding panel for smaller screens */}
            <div className={`${styles.navbarPanel} ${isOpen ? styles.open : ''}`}>
                <div className={styles.navbarPanelContent}>
                    <Link to="/" className={styles.navbarItem} onClick={toggleMenu}>Home</Link>
                    <Link to="/" className={styles.navbarItem} onClick={toggleMenu}>About</Link>
                    <Link to="/" className={styles.navbarItem} onClick={toggleMenu}>Contact</Link>
                </div>
            </div>

            {/* Backdrop to close the panel */}
            <div
                className={`${styles.backdrop} ${isOpen ? styles.backdropVisible : ''}`}
                onClick={toggleMenu}
            ></div>
        </nav>
    );
};

export default Navbar;
