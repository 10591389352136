import React from 'react';
import styles from './FloatBox.module.scss'

const FloatBox = ({  }) => {
    return (
        <div className={styles.FloatBox}>
            <div className={styles.wrapper}>
                <h3>Souls Strength Counselling and Wellness</h3>
                <div className={".subtitle1"}>Empowering You to Overcome Life's Challenges with Compassionate Support
                    and Personalized Care, Guiding You Toward Healing, Growth, and Lasting Well-Being
                </div>

            </div>
        </div>
    )
}

export default FloatBox;