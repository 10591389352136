// src/App.js
import React from 'react';
import {BrowserRouter as Router, useRoutes} from 'react-router-dom';
import { ThemeProvider } from './assets/theme/ThemeContext'; // Import the ThemeProvider
import routes from './pages/Routes';
import './App.scss';


function RouterComponent() {
    const routing = useRoutes(routes);
    return (
        <div className="App">
            {routing}
        </div>
    );
}

function App() {
    return (
        <ThemeProvider>
            <Router>
                <RouterComponent />
            </Router>
        </ThemeProvider>
    );
}

export default App;
